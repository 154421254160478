import { useEffect, Suspense, lazy } from "react";
import { Loader } from "semantic-ui-react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";

const About = lazy(() => import("./pages/mainsite/about"));
const Contact = lazy(() => import("./pages/mainsite/contact"));
const LandingPage = lazy(() => import("./pages/mainsite/landingpage"));
const ForEducators = lazy(() => import("./pages/mainsite/for-educators"));
const ForCompanies = lazy(() => import("./pages/mainsite/for-companies"));
const ForInstitutions = lazy(() => import("./pages/mainsite/for-institutions"));

const ForumList = lazy(() => import("./pages/forum/list"));
const ForumRead = lazy(() => import("./pages/forum/read"));

const EventList = lazy(() => import("./pages/events/list"));
const EventRead = lazy(() => import("./pages/events/read"));

const FormRead = lazy(() => import("./pages/form/form-read"));
const FormSuccess = lazy(() => import("./pages/form/form-success"));

const Notification = lazy(() => import("./pages/common/notification"));
const Profile = lazy(() => import("./pages/common/profile"));
const PageNotFound = lazy(() => import("./pages/common/notfound"));
const Support = lazy(() => import("./pages/common/support"));

const SeriesMainPage = lazy(() => import("./pages/series/mainpage"));
const SeriesFeature = lazy(() => import("./pages/series/feature"));
const SeriesRead = lazy(() => import("./pages/series/read"));
const CourseRead = lazy(() => import("./pages/series/resource/course"));

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader active inline style={{ margin: 10 }} />}>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<LandingPage />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/notification" element={<Notification />}></Route>
          <Route
            exact
            path="/profile"
            element={
              <Profile
                switch_profile_allowed={true}
                show_external_link={true}
              />
            }
          ></Route>
          <Route exact path="/for-educators" element={<ForEducators />}></Route>
          <Route exact path="/for-companies" element={<ForCompanies />}></Route>
          <Route
            exact
            path="/for-institutions"
            element={<ForInstitutions />}
          ></Route>
          <Route exact path="/support" element={<Support />}></Route>

          <Route exact path="/forum" element={<ForumList />}></Route>
          <Route exact path="/forum/r/:slug" element={<ForumRead />}></Route>
          <Route
            exact
            path="/forum/s/:searchKey"
            element={<ForumList />}
          ></Route>

          <Route exact path="/events" element={<EventList />}></Route>
          <Route exact path="/events/r/:slug" element={<EventRead />}></Route>
          <Route
            exact
            path="/events/s/:searchKey"
            element={<EventList />}
          ></Route>

          <Route exact path="/forms/:slug" element={<FormRead />} />
          <Route exact path="/success/form" element={<FormSuccess />} />

          <Route exact path="/series" element={<SeriesMainPage />}></Route>
          <Route
            exact
            path="/series/search/:query"
            element={<SeriesMainPage />}
          ></Route>

          <Route
            exact
            path="/section/:filter"
            element={<SeriesMainPage section />}
          ></Route>

          <Route
            exact
            path="/series/:feature"
            element={<SeriesFeature />}
          ></Route>
          <Route
            exact
            path="/series/:feature/:type/:id"
            element={<SeriesRead />}
          ></Route>

          <Route exact path="/learn/:slug" element={<CourseRead />}></Route>

          <Route exact path="*" element={<LandingPage />}></Route>
          {/*<Route exact path='*' element={<PageNotFound/>}></Route>*/}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
